<template>
  <v-card style="padding: 20px">
    <div style="text-align: center">
      <h3>
        Um diese Rechnung zu stornieren, muss entschieden werden, wie mit dem damit verbundenen Produkt umgegangen wird
      </h3>

      <div style="padding: 20px">
        <!-- K -->
        <div v-if="type === 'K'">
          <v-checkbox
            v-model="deleteProduct"
            label="Die Kerze soll gelöscht werden"
          ></v-checkbox>
        </div>

        <!-- KA -->
        <div v-if="type === 'KA'">
          <v-checkbox
            v-model="deleteProduct"
            label="Die Kerze soll gelöscht werden"
          ></v-checkbox>

          <v-checkbox
            v-model="informCustomer"
            label="Der Kunde soll per Mail über die Löschung informiert werden"
          ></v-checkbox>

          <v-checkbox
            v-model="cancelAbo"
            label="Das dazugehörige Abo soll für die Zukunft gekündigt werden"
          ></v-checkbox>
        </div>

        <!-- FE -->
        <div v-else-if="type === 'FE'">
          <v-checkbox
            v-model="basicVariant"
            label="Die Gedenkseite soll inaktiv werden"
          ></v-checkbox>

          <v-checkbox
            v-model="informCustomer"
            label="Der Kunde soll per Mail über die Inaktiv-Schaltung informiert werden"
          ></v-checkbox>
        </div>

        <!-- GPE -->
        <div v-else-if="type === 'GPE'">
         <v-checkbox
            v-model="basicVariant"
            label="Die Gedenk-Post soll in die Basis-Variante wechseln"
          ></v-checkbox>

          <v-checkbox
            v-model="deleteProduct"
            label="Die Gedenk-Post soll gar nicht mehr geschickt werden"
          ></v-checkbox>

          <v-checkbox
            v-model="informCustomer"
            label="Der Kunde soll per Mail über die Änderung informiert werden"
          ></v-checkbox>
        </div>

        <!-- IE -->
        <div v-else-if="type === 'IE'">
          <v-checkbox
            v-model="basicVariant"
            label="Die Gedenkseite soll inaktiv werden"
          ></v-checkbox>

          <v-checkbox
            v-model="informCustomer"
            label="Der Kunde soll per Mail über die Inaktiv-Schaltung informiert werden"
          ></v-checkbox>

          <v-checkbox
            v-model="cancelAbo"
            label="Das dazugehörige Abo soll für die Zukunft gekündigt werden"
          ></v-checkbox>
        </div>

        <!-- GP -->
        <div v-else-if="type === 'GP'">
          <v-checkbox
            v-model="basicVariant"
            label="Die Gedenk-Post soll in die Basis-Variante wechseln"
          ></v-checkbox>

          <v-checkbox
            v-model="deleteProduct"
            label="Die Gedenk-Post soll gar nicht mehr geschickt werden"
          ></v-checkbox>

          <v-checkbox
            v-model="informCustomer"
            label="Der Kunde soll per Mail über die Änderung informiert werden"
          ></v-checkbox>

          <v-checkbox
            v-model="cancelAbo"
            label="Das dazugehörige Abo soll für die Zukunft gekündigt werden"
          ></v-checkbox>
        </div>

        <!-- VM -->
        <div v-else-if="type === 'VM'">
          <v-checkbox
            v-model="informCustomer"
            label="Der Kunde soll per Mail über die Stornierung informiert werden"
          ></v-checkbox>

          <v-checkbox
            v-model="sendStornoInvoice"
            label="Der Kunde soll die Stornorechnung per Mail erhalten"
          ></v-checkbox>
        </div>

        <!-- Default -->
        <div v-else>
          Dieses Produkt ist noch nicht im internen System registriert. Bitte wende dich an einen Administrator
        </div>
      </div>

      <v-container>
        <v-row>
          <v-col cols="6">
            <v-btn width="100%" @click="closeDialog">
              Abbrechen
            </v-btn>
          </v-col>
          <v-col cols="6">
            <v-btn width="100%" @click="lastCheckDialog = true; error = false; success = false">
              Weiter
            </v-btn>
          </v-col>
        </v-row>
      </v-container>

    </div>

    <!-- Last Check -->
    <v-dialog max-width="600px" v-model="lastCheckDialog">
      <v-card style="padding: 20px">
        <v-alert type="error" v-model="error">
          Die Rechnung konnte nicht storniert werden.<br>
          <b>Error: </b>{{ errorText }}
        </v-alert>
        <h3>
          Möchtest du diese Rechnung wirklich stornieren?
        </h3>
        <p>
          Die Stornierung darf nur durchgeführt werden, wenn keine Zahlung zu dieser Rechnung existiert.
        </p>
        <p class="red--text" v-if="item.method !== 'invoice'">
          Eventuell muss zu dieser Zahlung noch Geld zurück überwiesen oder das Abo beim Zahlungsdienstleister gekündigt werden.
        </p>

        <v-container>
          <v-row>
            <v-col cols="6">
            <v-btn width="100%" @click="lastCheckDialog = false">
              Abbrechen
            </v-btn>
          </v-col>
          <v-col cols="6">
            <v-btn width="100%" class="error" @click="storno">
              Rechnung stornieren
            </v-btn>
          </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>

    <unvergessen-success-dialog :dialog="success" @close="success = false" heading="Die Rechnung wurde erfolgreich storniert" buttonText="Schließen"></unvergessen-success-dialog>
  </v-card>
</template>

<script>
import SuccessDialog from '@/components/global/SuccessDialog.vue'
import axios from 'axios'

export default {
  props: ['item', 'dialog'],
  computed: {
    type () {
      return this.item.invoiceNumber ? this.item.invoiceNumber.block : ''
    }
  },
  data () {
    return {
      success: false,
      error: false,
      errorText: '',
      deleteProduct: false,
      informCustomer: false,
      cancelAbo: false,
      basicVariant: false,
      sendStornoInvoice: false,
      lastCheckDialog: false
    }
  },
  methods: {
    resetVars () {
      this.deleteProduct = false
      this.informCustomer = false
      this.cancelAbo = false
      this.basicVariant = false
      this.sendStornoInvoice = false
      this.lastCheckDialog = false
    },
    closeDialog () {
      this.$emit('closeDialog')
    },
    storno () {
      axios.post('/crm/payment/storno', { id: this.item._id, deleteProduct: this.deleteProduct, informCustomer: this.informCustomer, cancelAbo: this.cancelAbo, basicVariant: this.basicVariant, sendStornoInvoice: this.sendStornoInvoice })
        .then(res => {
          this.$emit('updateObject', res.data)
          this.$emit('closeDialog')
          this.resetVars()
          this.success = true
          this.error = false
          this.errorText = ''
        })
        .catch(error => {
          this.success = false
          this.error = true
          this.errorText = error
        })
    }
  },
  watch: {
    dialog () {
      if (!this.dialog) this.resetVars()
    }
  },
  components: {
    unvergessenSuccessDialog: SuccessDialog
  }
}
</script>
